
import { createStore } from 'vuex'

// 引入插件
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    // 个人账户信息
    userInfo: {
      access_token: "",
      expires_in: "",
      token_type: ""
    },
    // 购物车 已加车数量
    quantity: 0,
    // 登录/无登录标识符
    session_id: '',
    // 首页切换标记
    homeFlag: 1
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setQuantity(state, params) {
      state.quantity = params;
    },
    setSession_id(state, params) {
      state.session_id = params;
    },
    setHomeFlag(state, params) {
      state.homeFlag = params
    }
  },
  actions: {
    setUserInfo(context, data) {
      context.commit("setUserInfo", data)
    },
    setQuantity(context, params) {
      context.commit("setQuantity", params)
    },
    setSession_id(context, params) {
      context.commit("setSession_id", params)
    },
    setHomeFlag(context, params) {
      context.commit("setHomeFlag", params)
    },
  },
  // vuex数据持久化配置 
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      // 存储的 key 的key值
      key: "store",
    })
  ]
})
